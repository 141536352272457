import { RouteComponentProps } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Accordion, AccordionItem } from '../components/Accordion';
import { PublicRoute } from '../components/PublicRoute';
import { SEO } from '../components/Seo';
import { H1 } from '../components/Typography';
import { Device } from '../utils/media-queries-utils';

const Container = styled.div`
  padding: 100px 220px;

  @media ${Device.laptopM} {
    padding: 100px 150px;
  }

  @media ${Device.laptop} {
    padding: 100px 75px;
  }

  @media ${Device.tablet} {
    padding: 100px 30px;
  }

  @media ${Device.mobileS} {
    padding: 100px 30px;
  }
`;

const ClerksGuide: FC<RouteComponentProps> = (props) => {
  const staticData = useStaticQuery(graphql`
    query {
      clerks1: file(relativePath: { eq: "clerks-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      clerks2: file(relativePath: { eq: "clerks-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      clerks3: file(relativePath: { eq: "clerks-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      clerks41: file(relativePath: { eq: "clerks-4-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      clerks42: file(relativePath: { eq: "clerks-4-2.png" }) {
        childImageSharp {
          fixed(width: 332) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      clerks43: file(relativePath: { eq: "clerks-4-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      clerks44: file(relativePath: { eq: "clerks-4-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      clerks45: file(relativePath: { eq: "clerks-4-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      clerks5: file(relativePath: { eq: "clerks-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      clerks61: file(relativePath: { eq: "clerks-6-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      clerks62: file(relativePath: { eq: "clerks-6-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      clerks7: file(relativePath: { eq: "clerks-7.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      clerks8: file(relativePath: { eq: "clerks-8.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <PublicRoute {...props}>
      <Container>
        <SEO title="Hypercite Clerks Guide" description="Clerks guide to interact with Hypercite" />
        <H1 className="mb-24" color="#051392">
          Clerks Guide
        </H1>

        <Accordion defaultOpenIndex={Number(props.location?.hash.substring(1))}>
          <AccordionItem title="Open AOB Attachments">
            <div className="flex items-center justify-center">
              <div className="w-full">
                <Img fluid={staticData.clerks1.childImageSharp.fluid} />
              </div>
            </div>
          </AccordionItem>

          <AccordionItem title="Access Source Files">
            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Img fluid={staticData.clerks2.childImageSharp.fluid} />
              </div>
            </div>
          </AccordionItem>

          <AccordionItem title="Drag and Drop Attachments">
            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Img fluid={staticData.clerks3.childImageSharp.fluid} />
              </div>
            </div>
          </AccordionItem>

          <AccordionItem title="Edit Links">
            <div className="flex items-center justify-center pb-12 border-b border-solid border-grey-800">
              <div className="w-full">
                <Img fluid={staticData.clerks41.childImageSharp.fluid} />
              </div>
            </div>

            <div className="flex items-center justify-center pb-12 border-b border-solid border-grey-800">
              <Img fixed={staticData.clerks42.childImageSharp.fixed} />
            </div>

            <div className="flex items-center justify-center pb-12 border-b border-solid border-grey-800">
              <div className="w-full">
                <Img fluid={staticData.clerks43.childImageSharp.fluid} />
              </div>
            </div>

            <div className="flex items-center justify-center pb-12 border-b border-solid border-grey-800">
              <div className="w-full">
                <Img fluid={staticData.clerks44.childImageSharp.fluid} />
              </div>
            </div>

            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Img fluid={staticData.clerks45.childImageSharp.fluid} />
              </div>
            </div>
          </AccordionItem>

          <AccordionItem title="View Multiple Screens">
            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Img fluid={staticData.clerks5.childImageSharp.fluid} />
              </div>
            </div>
          </AccordionItem>

          <AccordionItem title="Search">
            <div className="flex items-center justify-center pb-12 border-b border-solid border-grey-800">
              <div className="w-full">
                <Img fluid={staticData.clerks61.childImageSharp.fluid} />
              </div>
            </div>

            <div className="flex items-center justify-center pb-12 border-b border-solid border-grey-800">
              <div className="w-full">
                <Img fluid={staticData.clerks62.childImageSharp.fluid} />
              </div>
            </div>
          </AccordionItem>

          <AccordionItem title="Save Attachments">
            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Img fluid={staticData.clerks7.childImageSharp.fluid} />
              </div>
            </div>
          </AccordionItem>

          <AccordionItem title="Save Parent Document">
            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Img fluid={staticData.clerks8.childImageSharp.fluid} />
              </div>
            </div>
          </AccordionItem>
        </Accordion>
      </Container>
    </PublicRoute>
  );
};

export default ClerksGuide;
